import { useQuery, gql } from "@apollo/client";
import moment from "moment";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { getAllKeyInfo, filterSummaryData } from "./utils";
import Twemoji from "../../../Twemoji";
import Loading from "../../common/loading/Loading";

const GET_PATIENT_SUMMARY = gql`
  query PatientSummary($uuid: String, $last: Boolean) {
    patient(uuid: $uuid) {
      notes(last: $last) {
        uuid
        keyHistory
        keyMedications
        vaccinationStatus
        createdAt
      }
      keyHistory
      keyMedications
      vaccinationStatus
    }
  }
`;

function AddKeyInfo({
  patient,
  isHoveringKeyInfoButton,
  setIsHoveringKeyInfoButton,
}) {
  const navigate = useNavigate();
  const { patientUUID } = useParams();

  if (!patient) {
    return null;
  }

  return (
    <div
      onMouseEnter={() => setIsHoveringKeyInfoButton(true)}
      onMouseLeave={() => setIsHoveringKeyInfoButton(false)}
      className={
        "border-t-2 rounded-b-md dark:bg-gray-800 border-indigo-100 dark:border-gray-700 opacity-100 hover:bg-indigo-100 dark:hover:bg-gray-700 cursor-pointer flex items-center justify-center py-2 bg-white focus:outline-none transition-colors"
      }
      onClick={() => {
        navigate(`/notebook/${patientUUID}/keyinfo`);
      }}
    >
      <h4
        className={` ${
          isHoveringKeyInfoButton
            ? "text-indigo-500 dark:text-indigo-400"
            : "text-gray-600 dark:text-gray-200 "
        } transition-all`}
      >
        {!patient?.keyHistory &&
        !patient?.keyMedications &&
        !patient?.vaccinationStatus
          ? "+ Add Key Info..."
          : "Edit Key Info..."}
      </h4>
    </div>
  );
}

function SummaryItem({ date = null, text, noteUuid = null }) {
  const navigate = useNavigate();

  return (
    <div
      title="Click to go to source note"
      className="w-full"
      onClick={() => {
        navigate(`/edit_note/${noteUuid}`);
      }}
    >
      <div className="w-full flex flex-row items-center py-1">
        <div
          className={
            "flex-1 text-sm pr-2 dark:text-gray-100 whitespace-pre-wrap"
          }
        >
          {text}
        </div>

        {date ? (
          <div
            className={"text-[8pt] text-gray-600 dark:text-gray-500"}
          >
            {moment(date).format("MMM. DD, YYYY")}
          </div>
        ) : null}
      </div>
    </div>
  );
}

function Summary(props) {
  const { data } = useQuery(GET_PATIENT_SUMMARY, {
    variables: {
      uuid: props.patientUUID,
      last: false,
    },
    fetchPolicy: "no-cache",
  });
  const [isHoveringKeyInfoButton, setIsHoveringKeyInfoButton] =
    useState(false);

  const { allKeyHistories, allKeyMedications, allKeyVaccinations } =
    getAllKeyInfo(data);

  function renderKeyInformation(allKeyInformation, patientKeyInfo) {
    const filteredKeyInformation =
      filterSummaryData(allKeyInformation);

    if (filteredKeyInformation && filteredKeyInformation.length > 0) {
      return (
        <div className="overflow-y-scroll hover:cursor-pointer">
          {filteredKeyInformation.map(
            ({ noteUuid, text, createdAt }, index) => (
              <div
                key={index}
                className={
                  "flex flex-row items-center odd:bg-white ring-2 ring-inset ring-transparent hover:ring-indigo-500/50 dark:odd:bg-gray-800 even:bg-gray-50 dark:even:bg-gray-900/50 px-3"
                }
              >
                <SummaryItem
                  noteUuid={noteUuid}
                  text={text}
                  date={createdAt}
                />
              </div>
            ),
          )}
        </div>
      );
    } else {
      return (
        <div>
          {!patientKeyInfo && (
            <h5 className="text-sm text-center text-gray-500 pb-2">
              Not Recorded
            </h5>
          )}
        </div>
      );
    }
  }

  function renderPatientKeyInfo(text = "") {
    if (text) {
      return (
        <div
          className={`flex flex-row items-center px-3 py-1 rounded-lg mx-1 mb-1 transition-all whitespace-pre-wrap ${
            isHoveringKeyInfoButton
              ? "bg-indigo-100 dark:bg-indigo-500/100"
              : "bg-indigo-200/30"
          }`}
        >
          <div className="overflow-y-scroll flex-1">
            <h5 className={`text-[10pt] leading-5`}>{text}</h5>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  if (data && data.patient) {
    return (
      <div className="border-2 shadow-sm rounded-lg bg-white dark:bg-gray-800 border-indigo-100 dark:border-gray-700">
        <div className="grid grid-cols-1 md:grid-cols-3 auto-grid-rows divide-y-2 md:divide-x-2 md:divide-y-0 divide-solid divide-indigo-100 dark:divide-gray-700">
          <div className="pt-2">
            <h4 className="text-center dark:text-gray-300 pb-2">
              <Twemoji emoji={"🩺"} /> Key Medical History
            </h4>
            <div className="max-h-32 overflow-y-scroll">
              {renderPatientKeyInfo(data?.patient?.keyHistory)}
              {renderKeyInformation(
                allKeyHistories,
                data?.patient?.keyHistory,
              )}
            </div>
          </div>
          <div className="pt-2">
            <h4 className="text-center dark:text-gray-300 pb-2">
              <Twemoji emoji={"💊"} /> Chronic Medications
            </h4>
            <div className="max-h-32 overflow-y-scroll">
              {renderPatientKeyInfo(data?.patient?.keyMedications)}
              {renderKeyInformation(
                allKeyMedications,
                data?.patient?.keyMedications,
              )}
            </div>
          </div>
          <div className="pt-2">
            <h4 className="text-center dark:text-gray-300 pb-2">
              <Twemoji emoji={"💉"} /> Immunization Record
            </h4>
            <div className="max-h-32 overflow-y-scroll">
              {renderPatientKeyInfo(data?.patient?.vaccinationStatus)}
              {renderKeyInformation(
                allKeyVaccinations,
                data?.patient?.vaccinationStatus,
              )}
            </div>
          </div>
        </div>
        <AddKeyInfo
          patient={data?.patient}
          isHoveringKeyInfoButton={isHoveringKeyInfoButton}
          setIsHoveringKeyInfoButton={setIsHoveringKeyInfoButton}
        />
      </div>
    );
  } else {
    return <Loading shouldShow={true} />;
  }
}

export default Summary;
