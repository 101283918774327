import { Tooltip } from "@mui/material";
import React from "react";

import { AccountItemLine } from "./AccountItem";
import { BasicButton } from "../common/buttons/BasicButton.js";
import { NoteUsageCounter } from "../layout/header/NoteUsageCounter.js";
import { instantiateCustomerPortal } from "./../../api/Billing.js";

export function SubscriptionDetails({
  user,
  setShouldShowCancelSubscriptionModal,
}) {
  return (
    <>
      <div className="flex-1 divide-y dark:divide-gray-700">
        <AccountItemLine title="Plan Type:">
          {user?.account?.accountDetails?.planName}, recurring{" "}
          {user?.account?.accountDetails?.billingFrequency} (
          {user?.account?.accountDetails?.planPrice} /{" "}
          {user?.account?.accountDetails?.billingFrequency == "yearly"
            ? "year"
            : "month"}
          )
          {user?.account?.accountDetails?.isCanceled ? (
            <strong className="text-sm text-red-400">
              {" "}
              Canceled
            </strong>
          ) : null}
        </AccountItemLine>
        <AccountItemLine title="Upcoming invoice:">
          <ul className="text-xs md:text-sm">
            {user?.account?.accountDetails?.nextInvoiceInfo}
          </ul>
        </AccountItemLine>
        {user?.account?.accountType != "unlimited" ? (
          <AccountItemLine title="Usage:">
            <NoteUsageCounter small />{" "}
          </AccountItemLine>
        ) : null}
      </div>
      {user?.account?.isTeamsCustomer ? null : (
        <div
          id="accountSubscriptionOptions"
          className="flex flex-row md:flex-col md:items-end items-center justify-center space-x-2 md:space-x-0 md:space-y-2"
        >
          <Tooltip
            title={
              "Change plan, update billing details, update card information, change billing frequency, view past invoices, etc."
            }
            arrow
          >
            <span>
              <BasicButton
                onClick={async () => {
                  instantiateCustomerPortal();
                }}
              >
                Manage Plan
              </BasicButton>
            </span>
          </Tooltip>

          {!user?.account?.accountDetails?.isCanceled ? (
            <button
              className="text-xs font-medium text-gray-400 transition-all hover:text-red-700 focus:outline-none"
              onClick={() =>
                setShouldShowCancelSubscriptionModal(true)
              }
            >
              Cancel Subscription
            </button>
          ) : null}
        </div>
      )}
    </>
  );
}
