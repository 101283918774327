import React, { useState } from "react";

import { MessageModal } from "../components/common/modals/PopupModals.js";
import { TemplateModalList } from "../components/templates/TemplateModalList.js";
import { TemplateSelectProvider } from "../context/templateSelectContext.js";

const withTemplatesMessageModal = (Component) =>
  function TemplatesMessageModalWrappedComponent(props) {
    const [
      shouldShowTemplateSelectModal,
      setShouldShowTemplateSelectModal,
    ] = useState(false);
    const [selectedTemplateUuid, setSelectedTemplateUuid] =
      useState(null);
    const [
      uuidOfNoteToOverwriteWithTemplate,
      setUuidOfNoteToOverwriteWithTemplate,
    ] = useState(null);

    return (
      <TemplateSelectProvider
        value={{
          setShouldShowTemplateSelectModal,
          selectedTemplateUuid,
          setSelectedTemplateUuid,
          uuidOfNoteToOverwriteWithTemplate,
          setUuidOfNoteToOverwriteWithTemplate,
        }}
      >
        <MessageModal
          shouldShow={shouldShowTemplateSelectModal}
          setShouldShow={setShouldShowTemplateSelectModal}
          title="Select a template"
          dismissable
        >
          <div className="h-96 overflow-y-scroll">
            <TemplateModalList />
          </div>
        </MessageModal>
        <Component {...props} />
      </TemplateSelectProvider>
    );
  };
export default withTemplatesMessageModal;
