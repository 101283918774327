import axios from "axios";
import mixpanel from "mixpanel-browser";

import signS3 from "./SignS3.js";
import { bytesToMB } from "./utils.js";
import { alert } from "../components/common/Alert.js";

export async function uploadToCloud({
  blob,
  createNewNote,
  handleUploadProgress = null,
  jobType,
}) {
  const formData = new FormData();
  const signS3Response = await signS3();
  const { data, storageObjectName } = signS3Response.data;
  const audioStorageLink = data.url;
  const { fields } = data;

  for (const key in fields) {
    formData.append(key, fields[key]);
  }
  formData.append("file", blob);

  const cancelTokenSource = axios.CancelToken.source();

  // Log when the 10-second upload progress timeout occurs
  const uploadTimeout = setTimeout(() => {
    mixpanel.track("Upload Progress Timeout", {
      message: "Request timed out after 10 seconds",
      fileSizeMB: bytesToMB(blob.size).toFixed(2),
      audioStorageLink,
      formData,
    });
    cancelTokenSource.cancel("Request timed out after 10 seconds");
  }, 10000);

  // Log when the 120-second sync upload timeout occurs
  const syncUploadTimeout = setTimeout(() => {
    mixpanel.track("Sync Upload Timeout", {
      message: "Request timed out after 120 seconds",
      fileSizeMB: bytesToMB(blob.size).toFixed(2),
      audioStorageLink,
      formData,
    });
    cancelTokenSource.cancel("Request timed out after 120 seconds");
  }, 120000);

  mixpanel.track("Upload Started", {
    fileSizeMB: bytesToMB(blob.size).toFixed(2),
  });
  mixpanel.track("Upload Started", {
    fileSizeMB: bytesToMB(blob.size).toFixed(2),
  });

  try {
    await axios.post(data.url, formData, {
      headers: {
        "Access-Control-Allow-Origin": true,
      },
      transformRequest: (formData) => formData,
      cancelToken: cancelTokenSource.token,
      onUploadProgress: (progressEvent) => {
        clearTimeout(uploadTimeout);
        if (handleUploadProgress) {
          handleUploadProgress(progressEvent);
        }
      },
    });

    const newNoteData = await createNewNote(
      audioStorageLink,
      storageObjectName,
      jobType,
    );
    return { success: true, note: newNoteData };
  } catch (error) {
    if (axios.isCancel(error)) {
      mixpanel.track("Upload Canceled", {
        fileSizeMB: bytesToMB(blob.size).toFixed(2),
        formData,
        requestCancellationMessage: error.message,
        audioStorageLink,
      });
    } else {
      mixpanel.track("Upload Failed", {
        fileSizeMB: bytesToMB(blob.size).toFixed(2),
        uploadingError: error,
        formData,
        audioStorageLink,
      });
    }
    alert(
      "error",
      "An error occurred. Please sync this recording later.",
    );

    return { error: "Failed upload.", audioStorageLink, formData };
  } finally {
    clearTimeout(uploadTimeout);
    clearTimeout(syncUploadTimeout);
  }
}

export async function submitPreRecordedSampleAudio({
  createNewNote,
}) {
  /**
   * Used for submitting onboarding audio file if the user chooses to use pre-recorded.
   *
   * Why are we doing this?
   * Cause the user needs a NEW note so that they can edit it and see it in their dashboard after the process.
   * However, we need to make that new note with an audio file. So saving the file in assets, then getting the
   *   blob, then uploading the file with the same function as regular recordings used seems to keep things clean
   * while allowing a new note to be created every time.
   *
   */

  const audioUrl = require("../assets/other/onboarding-appt-example.wav");

  try {
    const response = await fetch(audioUrl);
    const blob = await response.blob();
    const newNoteData = await uploadToCloud({
      blob,
      createNewNote,
    });
    return newNoteData;
  } catch (error) {
    alert(
      "error",
      "An error occurred. Please try again later or contact Scribenote Support.",
    );
    throw error;
  }
}

export async function submitTestingSampleAudio({
  createNewNote,
  audioUrl,
}) {
  /**
   * Used for submitting testing audio files if the tester chooses to use pre-record
   *
   * Why are we doing this?
   * Cause the user needs a NEW note so that they can edit it and see it in their dashboard after the process.
   * However, we need to make that new note with an audio file. So saving the file in assets, then getting the
   *   blob, then uploading the file with the same function as regular recordings used seems to keep things clean
   * while allowing a new note to be created every time.
   *
   */

  const importedAudioUrl = audioUrl;

  fetch(importedAudioUrl)
    .then((response) => response.blob())
    .then((blob) => {
      uploadToCloud({ blob, createNewNote });
    })
    .catch(() => {
      alert(
        "error",
        "An error occurred. Please try again later or contact Scribenote Support.",
      );
    });
}
