import { useMutation, useLazyQuery, gql } from "@apollo/client";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import mixpanel from "mixpanel-browser";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { animated, useTransition } from "react-spring";

import ChooChoo from "../../../assets/imgs/choo-choo.png";
import ProgressIndicator from "../ProgressIndicator";
import EditNoteOnboardingStep from "./EditNoteOnboardingStep";
import ExplanationStep from "./ExplanationStep";
import FinalStep from "./FinalStep";
import RecordOnboardingStep from "./RecordOnboardingStep";
import { submitPreRecordedSampleAudio } from "../../../api/UploadAudio";
import SEND_EXPORT_NOTE_EMAIL from "../../../graphql/mutations/SendExportNoteEmail.js";
import { useOnboarding } from "../../../hooks";
import { SaveButton } from "../../common/buttons/SaveButton";
import { ConfirmModal } from "../../common/modals/PopupModals";
import { MODIFY_NOTE } from "../../notes/edit/api/mutations";

export const GET_NOTE = gql`
  query getNote($uuid: String) {
    note(uuid: $uuid) {
      uuid
      audioStorageLink
      subjective
      objective
      assessment
      plan
      isTagged
      isDraft
      isMarkedAsProcessing
      isMarkedAsFailed
      isMarkedAsReview
      user {
        email
      }
    }
  }
`;

export function NextStepButton({
  onClick,
  disabled,
  withSpinner,
  disabledText,
  text = "Next →",
}) {
  return (
    <div className="relative group mt-6 flex items-center justify-center h-12 w-44">
      {disabled ? null : (
        <div
          style={{
            boxShadow: "0px 0px 6px 4px rgba(99, 102, 241, 0.8)",
          }}
          className="w-full h-full animate-pulse rounded-full bg-transparent hover:animate-none"
        ></div>
      )}

      <div className="absolute">
        <SaveButton
          disabled={disabled}
          withSpinner={withSpinner}
          disabledText={disabledText}
          onClick={onClick}
        >
          {text}
        </SaveButton>
      </div>
    </div>
  );
}

const STEP_NAMES = [
  "Start",
  "Medical Records",
  "Make a Record",
  "Edit SOAP",
  "Check Email",
];

export default function FirstLoginTour() {
  const [currentStepNumber, setCurrentStepNumber] = useState(0);
  const [shouldShowCloseModal, setShouldShowCloseModal] =
    useState(false);
  const { shouldShowFirstLoginTour, setShouldShowFirstLoginTour } =
    useOnboarding();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const [getNote, { data }] = useLazyQuery(GET_NOTE, {
    fetchPolicy: "network-only",
    onError: (error) => {
      mixpanel.track("Error fetching note", {
        error: error.message,
      });
    },
  });

  const [modifyNote] = useMutation(MODIFY_NOTE);
  const [sendExportNoteEmail] = useMutation(SEND_EXPORT_NOTE_EMAIL);

  useEffect(() => {
    // "document.documentElement.scrollTo" is the magic for React Router Dom v6
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Optional if you want to skip the scrolling animation
    });
  }, [currentStepNumber]);

  const transitions = useTransition(currentStepNumber, {
    key: currentStepNumber,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 300 },
    exitBeforeEnter: true,
  });

  async function handleSubmission(createNewNote) {
    setIsSubmitting(true);
    try {
      const newNoteData = await submitPreRecordedSampleAudio({
        createNewNote,
      });
      await getNote({
        variables: {
          uuid: newNoteData?.note?.data?.createQuickNote?.note?.uuid,
        },
      });
      setIsSubmitting(false);
      goToNextStep();
    } catch (error) {
      mixpanel.track("Error submitting pre-recorded audio", {
        error: error.message,
      });
      setIsSubmitting(false);
    }
  }

  async function handleModifyNote(noteInput) {
    setIsSubmitting(true);
    try {
      await modifyNote({
        variables: { noteInput },
      });
      await sendExportNoteEmail({
        variables: {
          toEmail: data?.note?.user?.email,
          grantedNoteUuid: noteInput.noteUuid,
          isForAssistant: false,
          specialInstructions:
            "This is the SOAP note you just made using Scribenote!",
          areAttachmentsIncluded: false,
          shouldEmbedEmailExports: true,
        },
      });
      setIsSubmitting(false);
      goToNextStep();
    } catch (error) {
      mixpanel.track("Error modifying note", {
        error: error.message,
        toEmail: data?.note?.user?.email,
        grantedNoteUuid: noteInput.noteUuid,
      });
      setIsSubmitting(false);
    }
  }

  function goToNextStep() {
    mixpanel.track(
      `Completed ${currentStepNumber + 1}st Onboarding Page`,
    );
    setCurrentStepNumber(currentStepNumber + 1);
  }

  function goToStep(step) {
    setCurrentStepNumber(step);
  }

  function renderStep(i) {
    if (i === 0) {
      return (
        <>
          <div className="flex flex-col items-center justify-center">
            <h1 className="text-center max-w-xl w-[300px] md:w-auto md:max-w-3xl font-bold">
              Hop on board the Scribenote Express!
            </h1>
            <div className="overflow-x-auto w-[360px] md:w-full md:mx-4">
              <img
                className="md:w-[700px] min-w-[1000px] md:min-w-[100px] py-2 mb-10 mx-auto"
                src={ChooChoo}
              />
            </div>
            <p className="w-[300px] md:w-[500px] text-center mt-3">
              To get you up-to-speed, we&apos;ll be showing you how to
              record your Appointments with Scribenote.
              <br />
              <br />
              <strong>This should only take 3-5 minutes.</strong>
            </p>
          </div>
          <NextStepButton onClick={goToNextStep} />
        </>
      );
    }
    if (i === 1) {
      return <ExplanationStep goToNextStep={goToNextStep} />;
    }
    if (i === 2) {
      return (
        <RecordOnboardingStep
          handleSubmission={handleSubmission}
          isSubmitting={isSubmitting}
        />
      );
    }
    if (i === 3) {
      return (
        <EditNoteOnboardingStep
          noteData={data}
          handleModifyNote={handleModifyNote}
          isSubmitting={isSubmitting}
        />
      );
    }
    if (i === 4) {
      return <FinalStep goToStep={goToStep} />; // dont show steps here
    }
  }

  if (shouldShowFirstLoginTour === false) {
    return (
      <div className="flex flex-col h-screen items-center justify-center space-y-10">
        <h2>Whoops, we can&apos;t take you there.</h2>
        <h5 className="max-w-xl">
          Our records indicate you&apos;ve already completed the
          tutorial. To prevent mis-use, you cannot complete the
          tutorial again. If you&apos;d like to learn more about
          Scribenote, please contact our support team.
        </h5>
        <a href="/dashboard" className="cursor-pointer">
          <h4 className="underline text-indigo-500">
            Click here to return to the Dashboard
          </h4>
        </a>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-white dark:bg-gray-800 px-4 py-8 md:px-20 md:py-10 flex flex-col items-center justify-between overflow-y-scroll">
      <ConfirmModal
        shouldShow={shouldShowCloseModal}
        hideModal={() => setShouldShowCloseModal(false)}
        title={"Exit Tutorial?"}
        cancelText={"Yes, Exit"}
        confirmText={"Stay"}
        cancelAction={() => {
          setShouldShowFirstLoginTour(false);
          mixpanel.track("Exited Onboarding", {
            onboardingPage: currentStepNumber,
          });
          navigate("/dashboard");
        }}
        confirmAction={() => setShouldShowCloseModal(false)}
      >
        <h5>
          Are you sure? This is really important for getting you
          up-and-running with Scribenote, and you won&apos;t be able
          to revisit it!
        </h5>
      </ConfirmModal>

      <ProgressIndicator
        currentStepNumber={currentStepNumber}
        stepNames={STEP_NAMES}
      />

      <button
        onClick={() => setShouldShowCloseModal(true)}
        className="fixed top-4 right-4 text-xl"
      >
        <FontAwesomeIcon icon={faTimes} color="red" />
      </button>

      {transitions((style, i) => (
        <animated.div
          className="flex flex-1 flex-col items-center justify-evenly space-y-4 mt-10"
          style={style}
        >
          {renderStep(i)}
        </animated.div>
      ))}
    </div>
  );
}
