import { faUser } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { AccountItem, AccountItemLine } from "./AccountItem";

export default function ProfileSettings({ user }) {
  const navigate = useNavigate();

  return (
    <AccountItem
      title="Profile"
      icon={<FontAwesomeIcon icon={faUser} size="lg" color="gray" />}
    >
      <div className="flex-1 flex mr-4 md:space-x-4">
        <div className="flex-1 divide-y dark:divide-gray-700">
          <AccountItemLine title="Name:">
            {user?.firstName} {user?.lastName}
          </AccountItemLine>
          <AccountItemLine title="Email:">
            <Tooltip
              arrow
              placement="right"
              title={
                user?.isConfirmed
                  ? "You have successfully confirmed your email."
                  : "You have not confirmed your email yet."
              }
            >
              <div className="flex flex-row items-center">
                {user?.email}{" "}
                {user?.isConfirmed ? (
                  <span className="text-green-500 text-xs ml-1">
                    ✓
                  </span>
                ) : (
                  <>
                    <div className="flex flex-row items-center space-x-3">
                      <span className="text-red-500 text-xs ml-1">
                        ✗
                      </span>

                      <button
                        className="text-sm font-medium text-indigo-400 transition-all hover:text-indigo-700 focus:outline-none"
                        onClick={() =>
                          navigate("/registration_confirmation")
                        }
                      >
                        Manage Email
                      </button>
                    </div>
                  </>
                )}
              </div>
            </Tooltip>
          </AccountItemLine>
          <AccountItemLine title="User ID:">
            {user?.uuid}
          </AccountItemLine>
        </div>
      </div>
    </AccountItem>
  );
}
