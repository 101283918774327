import { useSelect } from "downshift";
import React from "react";

function ArrowIcon({ isOpen }) {
  return (
    <svg
      viewBox="0 0 20 20"
      preserveAspectRatio="true"
      width={11}
      fill="transparent"
      stroke="#6b7280"
      strokeWidth="3px"
      transform={isOpen ? "rotate(180)" : undefined}
    >
      <path d="M1,6 L10,15 L19,6" />
    </svg>
  );
}

export default function CreatePatientSpeciesDropdown({
  itemsAsObjects,
  handleSelectedItemChange,
  selectedItem,
  inputClassName,
  dataCy,
}) {
  const isOpenClassName =
    "appearance-none w-full block px-3 py-2 my-2 border border-gray-100 dark:bg-gray-700 dark:border-gray-700 text-left bg-gray-100 placeholder-gray-400 text-gray-900 dark:text-gray-200 rounded-md focus:outline-none ring-2 ring-indigo-300";
  const itemToString = (item) => (item ? item.label : "");
  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    items: itemsAsObjects,
    itemToString,
    selectedItem,
    onSelectedItemChange: handleSelectedItemChange,
  });

  return (
    <div className="relative cursor-pointer">
      <div className="relative">
        <button
          name="selectedSpecies"
          type="button"
          {...getToggleButtonProps()}
          onKeyDown={() => {
            // This prevents a crash by overwriting downshift searching on input
            console.log("Prevent input.");
          }}
          className={isOpen ? isOpenClassName : inputClassName}
          data-cy={dataCy ? dataCy : "pSpeciesDropdown"}
        >
          {selectedItem ? (
            itemToString(selectedItem)
          ) : (
            <h5 className="text-sm text-gray-400 dark:text-gray-400">
              Select a species...
            </h5>
          )}
        </button>
        <button
          className="appearance-none absolute z-10 right-4 top-3.5 focus:outline-none"
          {...getToggleButtonProps()}
          type="button"
        >
          <ArrowIcon isOpen={isOpen} />
        </button>
      </div>

      <ul
        {...getMenuProps()}
        onKeyDown={() => {
          // This prevents a crash by overwriting downshift searching on input
          console.log("Prevent input.");
        }}
        className={
          isOpen
            ? "appearance-none focus:outline-none absolute z-20 shadow-md w-full border bg-gray-100 dark:bg-gray-700 border-gray-100 dark:border-gray-800 rounded-lg max-h-24 overflow-y-scroll transform -translate-y-2"
            : "hidden"
        }
      >
        {isOpen &&
          itemsAsObjects.map((item, index) => {
            return (
              <li
                className={
                  highlightedIndex === index
                    ? "py-1 px-2 bg-indigo-100 dark:bg-indigo-800 dark:text-gray-200 text-xs"
                    : "py-1 px-2 bg-gray-100 dark:bg-gray-700 text-xs dark:text-gray-200"
                }
                key={item.name}
                data-cy={item.name}
                {...getItemProps({ item, index })}
                onInputCapture={() => {}}
              >
                {item.label}
              </li>
            );
          })}
      </ul>
    </div>
  );
}
