import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { useLiveQuery } from "dexie-react-hooks";
import mixpanel from "mixpanel-browser";
import moment from "moment";
import React, { useState, useEffect, useRef } from "react";
import { Controller } from "react-hook-form";
import {
  config,
  useTransition,
  useSpring,
  animated,
} from "react-spring";

import RecordingJobTypeSelection from "./RecordingJobTypeSelection.js";
import { RecordingSection } from "./RecordingSection";
import UnsyncedRecordings from "./UnsyncedRecordings.js";
import { UploadFinishedButton } from "./UploadFinishedButton.js";
import { uploadToCloud } from "../../../api/UploadAudio.js";
import { db } from "../../../db.js";
import { useDarkMode, useNoteUsage } from "../../../hooks";
import { useAuth } from "../../../hooks";
import { usePreventRecordingInterruptions } from "../../../hooks";
import { alert } from "../Alert.js";

const PREVENT_NAVIGATION_MESSAGE =
  "You have unsaved changes. Are you sure you want to leave this page.";

function RecordingTitle({ control, uploadStatus }) {
  return (
    <div className="self-center w-full flex-1" id="recorderTitle">
      <Controller
        render={({ field }) => (
          <TextareaAutosize
            minRows={1}
            maxRows={1}
            placeholder={"📝 Note title (optional)"}
            disabled={uploadStatus < 100}
            className="resize-none appearance-none relative block w-full px-2 py-[7px] border dark:bg-gray-700 dark:border-gray-700 dark:text-gray-200 border-gray-100 bg-gray-100 placeholder-gray-400 text-gray-900 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-300 focus:z-10 text-xs md:text-sm h-8 leading-normal"
            {...field}
          />
        )}
        control={control}
        name={"title"}
      />
    </div>
  );
}

const Record = ({
  createNewNote,
  id,
  isRecording,
  setIsRecording,
  control,
  shouldShowTitleInput = true,
  shouldUseSmallSize = false,
  shouldShowTypeDropdown = true,
  reset,
}) => {
  const [uploadStatus, setUploadStatus] = useState(100);
  const [isFinishedUploading, setIsFinishedUploading] =
    useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [
    selectedJobTypeForUnsyncedRecordings,
    setSelectedJobTypeForUnsyncedRecordings,
  ] = useState("medical_record");
  const { reauthenticateActiveUser, checkIsTokenExpiringSoon } =
    useAuth();
  const [
    shouldShowUnsyncedRecordings,
    setShouldShowUnsyncedRecordings,
  ] = useState(false);

  const { shouldUseAutoSoaps } = useNoteUsage();

  const { setShouldPreventInterruptions } =
    usePreventRecordingInterruptions();

  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const streamRef = useRef(null);

  const darkModeEnabled = useDarkMode();

  const allItems = useLiveQuery(() =>
    db.unsyncedRecordings.toArray(),
  );

  let trackColor = "#E4E4E7";
  if (darkModeEnabled[0]) {
    trackColor = "#1A2232";
  }

  const greenCheckAnim = useTransition(isFinishedUploading, {
    from: { opacity: 0, transform: "scale(0.8)" },
    enter: { opacity: 1, transform: "scale(1)" },
    leave: { opacity: 0, transform: "scale(0.8)" },
    reverse: isFinishedUploading,
    config: config.wobbly,
    // onRest: () => setIsFinishedUploading(!isFinishedUploading),
  });

  const fadeButton = useSpring({
    config: config.wobbly,
    opacity: isFinishedUploading ? 0 : 1,
    transform: isFinishedUploading ? "scale(0.8)" : "scale(1)",
  });

  const eventListener = (event) => {
    if (isRecording) {
      event.preventDefault();
      return PREVENT_NAVIGATION_MESSAGE;
    } else {
      return null;
    }
  };

  useEffect(() => {
    window.addEventListener("beforeunload", eventListener);

    return () => {
      window.removeEventListener("beforeunload", eventListener);
    };
  }, [isRecording]);

  function pauseRecording() {
    setIsPaused(true);

    mediaRecorderRef.current.pause();
  }

  function startPausedRecording() {
    setIsPaused(false);

    mediaRecorderRef.current.resume();
  }

  async function checkAvailableDevices() {
    let audioAvailable = false;
    let videoAvailable = false;

    const devices = await navigator.mediaDevices.enumerateDevices();
    devices.forEach((device) => {
      if (device.kind === "audioinput") {
        audioAvailable = true;
      }
      if (device.kind === "videoinput") {
        videoAvailable = true;
      }
    });

    return { audioAvailable, videoAvailable };
  }

  async function startRecording() {
    if (!isRecording) {
      //check if the user's token will expire soon
      const isTokenExpiringSoon = await checkIsTokenExpiringSoon();
      if (isTokenExpiringSoon) {
        await reauthenticateActiveUser();
      }
      setIsLoading(true);
      mixpanel.track("Start Recording");

      const constraints = {
        audio: true,
        video: false,
      };

      try {
        //Check available devices before requesting the media stream
        const { audioAvailable, videoAvailable } =
          await checkAvailableDevices();

        if (!audioAvailable) {
          //Inform the user that no microphone is available
          alert(
            "error",
            "No audio input device found. Please connect a microphone.",
          );
          mixpanel.track("Recording Error", {
            error: "No audio input device found",
            audioAvailable,
            videoAvailable,
          });
          setIsLoading(false);
          return;
        }

        //Proceed with requesting the media stream
        let stream = await navigator.mediaDevices.getUserMedia(
          constraints,
        );

        streamRef.current = stream;
        mediaRecorderRef.current = new MediaRecorder(stream);
        mediaRecorderRef.current.ondataavailable = (event) => {
          audioChunksRef.current.push(event.data);
        };
        mediaRecorderRef.current.start();

        mediaRecorderRef.current.onstart = function () {
          setIsRecording(true);
          setShouldPreventInterruptions(true);
          setIsLoading(false);
        };
      } catch (error) {
        const { audioAvailable, videoAvailable } =
          await checkAvailableDevices();

        mixpanel.track("Recording Error", {
          error: error.message || error.name,
          audioAvailable,
          videoAvailable,
        });

        alert(
          "error",
          "There was an error starting the recording. Please check your microphone and try again.",
        );
      }
    } else {
      mixpanel.track(
        "Attempted to start a recording with another recording in progress",
      );
    }
  }

  function handleUploadProgress(progressEvent) {
    var percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total,
    );
    if (percentCompleted === 100) {
      setIsFinishedUploading(true);
    }
    setUploadStatus(percentCompleted);
  }

  function stopMediaRecorder(mediaRecorder) {
    mediaRecorder.stop();

    // Release the tracks, otherwise the browser will still be using the microphone
    for (const track of mediaRecorder.stream.getTracks()) {
      track.stop();
    }
  }

  async function cancelRecording() {
    setIsRecording(false);
    setShouldPreventInterruptions(false);
    setIsPaused(false);

    stopMediaRecorder(mediaRecorderRef.current);

    audioChunksRef.current = [];
    streamRef.current = null;
    mediaRecorderRef.current = null;
  }

  async function stopRecording() {
    setIsPaused(false);
    setUploadStatus(0);
    if (!mediaRecorderRef.current) {
      mixpanel.track(
        "Attempted to stop a recording with no recording",
      );
      return;
    }
    mixpanel.track("Stop Recording", {
      recordingRef: mediaRecorderRef.current,
    });
    stopMediaRecorder(mediaRecorderRef.current);
    mediaRecorderRef.current.onstop = async () => {
      const blob = new Blob(audioChunksRef.current, {
        type: "audio/wav",
      });
      audioChunksRef.current = [];
      streamRef.current = null;
      mediaRecorderRef.current = null;

      const id = await db.unsyncedRecordings.add({
        saved_date: moment().format(),
        is_synced: false,
        note_uuid: null,
        blob: blob,
        jobType: selectedJobTypeForUnsyncedRecordings,
      });

      uploadToCloud({
        blob,
        handleUploadProgress,
        createNewNote,
      })
        .then((response) => {
          if (response?.success == true) {
            db.unsyncedRecordings.delete(id);
          }
          setIsRecording(false);
          setShouldPreventInterruptions(false);
          reset({ title: "" });
          mixpanel.track("Complete Recording");
        })
        .catch((error) => {
          const errorMessage =
            error?.error || error?.message || "Unknown error";
          const audioStorageLink = error?.audioStorageLink || "N/A";
          const formData = error?.formData || "N/A";

          cancelRecording();
          mixpanel.track("Failed to upload recording", {
            stopRecordingError: errorMessage,
            audioStorageLink,
            formData,
          });
          alert(
            "warning",
            "Your recording was saved but could not be uploaded. Try syncing it from the Sync Recordings menu.",
          );
        });
    };
  }

  return (
    <div className="h-full flex flex-col items-center">
      <div className="w-full flex flex-row items-start space-x-2">
        {shouldShowTitleInput ? (
          <RecordingTitle
            control={control}
            uploadStatus={uploadStatus}
          />
        ) : null}
        {shouldShowTypeDropdown && shouldUseAutoSoaps ? (
          <RecordingJobTypeSelection
            setSelectedJobTypeForUnsyncedRecordings={
              setSelectedJobTypeForUnsyncedRecordings
            }
          />
        ) : null}
      </div>

      <div
        className={`flex flex-col ${
          shouldUseSmallSize
            ? "w-44 h-44 md:h-52 md:w-52"
            : "h-60 w-60"
        } justify-center items-center mt-16`}
      >
        {isFinishedUploading ? (
          <UploadFinishedButton
            uploadStatus={uploadStatus}
            trackColor={trackColor}
            transitions={greenCheckAnim}
            setIsFinishedUploading={setIsFinishedUploading}
          />
        ) : (
          <animated.div
            style={fadeButton}
            className="w-full h-full flex items-center justify-center"
          >
            <RecordingSection
              id={id}
              isRecording={isRecording}
              isPaused={isPaused}
              isLoading={isLoading}
              audio={streamRef.current}
              uploadStatus={uploadStatus}
              isFinishedUploading={isFinishedUploading}
              startRecording={startRecording}
              stopRecording={stopRecording}
              pauseRecording={pauseRecording}
              cancelRecording={cancelRecording}
              startPausedRecording={startPausedRecording}
              trackColor={trackColor}
            />
          </animated.div>
        )}
      </div>
      <div className="relative mt-12">
        {allItems?.length > 0 ? (
          <>
            <button
              onClick={() => setShouldShowUnsyncedRecordings(true)}
              className="rounded-full shadow-md bg-indigo-500  self-center py-1.5 px-3 text-white font-medium hover:bg-indigo-600 focus:outline-none transition-all"
            >
              Sync Recordings
              <div className="absolute -top-2 -right-3 h-6 min-w-[24px] px-1 bg-red-400 flex items-center justify-center rounded-full">
                {allItems?.length}
              </div>
            </button>
            {shouldShowUnsyncedRecordings ? (
              <UnsyncedRecordings
                shouldShowUnsyncedRecordings={
                  shouldShowUnsyncedRecordings
                }
                setShouldShowUnsyncedRecordings={
                  setShouldShowUnsyncedRecordings
                }
                createNewNote={createNewNote}
              />
            ) : null}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default Record;
