import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

import { NextStepButton } from "./FirstLoginTour";
import Twemoji from "../../../Twemoji";
import { AudioPlayer } from "../../notebook/note/AudioPlayer";
import { OnboardingSOAPInput } from "../../notes/edit/form/OnboardingSOAPInput";

export default function EditNoteOnboardingStep({
  noteData,
  handleModifyNote,
  isSubmitting,
}) {
  const { control, reset, getValues } = useForm();

  useEffect(() => {
    if (noteData && noteData?.note) {
      const { subjective, objective, assessment, plan } =
        noteData.note;
      reset({
        subjective,
        objective,
        assessment,
        plan,
      });
    }
  }, [noteData]);

  function handleSubmitClick() {
    const { subjective, objective, assessment, plan } = getValues();
    handleModifyNote({
      noteUuid: noteData?.note?.uuid,
      subjective,
      objective,
      assessment,
      plan,
    });
  }

  return (
    <>
      <div>
        <h3 className="text-center">
          Voila... Here&apos;s the SOAP! <Twemoji emoji="🧼" />
        </h3>
        <p>
          You can edit it here if there&apos;s anything we missed.
        </p>
      </div>

      <div className="flex flex-col space-y-3 w-full md:w-[700px]">
        <div className="mb-6">
          <AudioPlayer
            noteUuid={noteData?.note?.uuid}
            isTagged={noteData?.note?.isTagged}
            shouldShowDeleteButton={false}
            shouldShowAddButton={false}
          />
        </div>

        <OnboardingSOAPInput
          control={control}
          title={"Subjective"}
          fieldName="subjective"
          name="subjective"
          headerStyle="bg-blue-400 dark:bg-blue-600"
          boxStyle="border-blue-300 bg-blue-50 dark:bg-gray-700 dark:border-blue-600"
        />
        <OnboardingSOAPInput
          control={control}
          title={"Objective"}
          fieldName="objective"
          name="objective"
          headerStyle="bg-pink-400 dark:bg-pink-600"
          boxStyle="border-pink-300 bg-pink-50 dark:bg-gray-700 dark:border-pink-600"
        />
        <OnboardingSOAPInput
          control={control}
          title={"Assessment"}
          fieldName="assessment"
          name="assessment"
          headerStyle="bg-green-400 dark:bg-green-600"
          boxStyle="border-green-300 bg-green-50 dark:bg-gray-700 dark:border-green-600"
        />
        <OnboardingSOAPInput
          control={control}
          title={"Plan"}
          fieldName="plan"
          name="plan"
          headerStyle="bg-yellow-400 dark:bg-yellow-600"
          boxStyle="border-yellow-300 bg-yellow-50 dark:bg-gray-700 dark:border-yellow-600"
        />
      </div>

      <div className="mt-10">
        <NextStepButton
          disabled={isSubmitting}
          onClick={handleSubmitClick}
          text={"Save →"}
        />
      </div>
    </>
  );
}
