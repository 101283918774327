import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";

import { alert } from "./Alert";

export default function InviteLink({ referralLink }) {
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsCopied(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, [isCopied]);

  async function copy(textToCopy) {
    navigator.clipboard.writeText(textToCopy).then(() => {
      alert(
        "info",
        "Copied invite link. Now share it with the world! 🌎",
      );
    });
  }

  return (
    <Tooltip
      arrow
      placement="bottom"
      title={isCopied ? "Copied!" : "Click to copy link"}
    >
      <button
        className="text-indigo-500 hover:text-indigo-600 dark:text-indigo-400 dark:hover:text-indigo-300 text-sm"
        onClick={() => {
          copy(referralLink);
          setIsCopied(true);
        }}
      >
        <div className="dark:bg-gray-700 bg-indigo-50 rounded-lg py-1.5 px-3 text-xs md:text-sm ring-0 hover:ring-2 hover:ring-indigo-600 dark:hover:ring-indigo-300 font-normal transition-all">
          {referralLink}
          <FontAwesomeIcon
            className="ml-4"
            icon={isCopied ? faCheck : faCopy}
          />
        </div>
      </button>
    </Tooltip>
  );
}
