import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";

import { Thumbnail } from "./Thumbnail";
import Twemoji from "../../Twemoji";

export function AttachmentsPreview({
  attachments = [],
  attachmentPreviewText,
  selectedAttachments,
  handleAttachmentClick,
  isForPatientProfile,
  isCompactView,
  patientUUID,
  emptyStateText,
}) {
  const navigate = useNavigate();

  function renderViewAllAttachments() {
    if (patientUUID && isForPatientProfile) {
      return (
        <div
          className={
            "border-t-2 rounded-b-md dark:bg-gray-800 border-indigo-100 dark:border-gray-700 opacity-100 hover:bg-indigo-100 dark:hover:bg-gray-700 cursor-pointer flex items-center justify-center py-2 bg-white focus:outline-none transition-colors"
          }
          onClick={() => {
            navigate(`/notebook/${patientUUID}/attachments`);
          }}
        >
          <h4 className="hover:text-indigo-500 hover:dark:text-indigo-400 text-gray-600 dark:text-gray-200 transition-all">
            View All Attachments...
          </h4>
        </div>
      );
    }
  }

  function renderEmptyState() {
    const textClassName =
      "text-center text-xs md:text-sm text-gray-400 dark:text-gray-500 my-4";
    return (
      <div
        className={`flex flex-1 items-center justify-center overflow-y-scroll p-2 ${
          isCompactView ? "h-10" : "h-32"
        }`}
      >
        {emptyStateText ? (
          <h5 className={textClassName}>{emptyStateText}</h5>
        ) : (
          <h5 className={textClassName}>
            Looks like you have no attachments for this patient yet.
            Try uploading one in a note or on the patient&apos;s
            attachments page!
          </h5>
        )}
      </div>
    );
  }

  function renderListOfAttachments() {
    return (
      <div
        className={`${
          isCompactView ? "h-32" : "h-44"
        } overflow-y-scroll`}
      >
        <div className="flex flex-row pb-2 px-1 flex-wrap">
          {attachments.map((attachment, index) => {
            let isAttachmentSelected = false;
            if (
              selectedAttachments &&
              (attachment.uuid === selectedAttachments[0]?.uuid ||
                attachment.uuid === selectedAttachments[1]?.uuid)
            ) {
              isAttachmentSelected = true;
            }
            return (
              <div
                key={index}
                className="flex flex-col items-center mx-2 my-2.5"
              >
                <div className="relative max-w-fit w-full hover:scale-105 transition-transform">
                  <Thumbnail
                    attachment={attachment}
                    handleAttachmentClick={handleAttachmentClick}
                    isAttachmentSelected={isAttachmentSelected}
                  />
                </div>
                <h5 className="text-xs text-center mt-1 line-clamp-1 px-1 max-w-[90px]">
                  {attachment.fileName}
                </h5>
                <span className="text-[10px] text-gray-500 dark:text-gray-400">
                  {moment(attachment.createdAt).format("MMM Do YYYY")}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <div className="relative border-2 shadow-sm rounded-lg border-indigo-100 dark:border-gray-700 pt-6">
      <div className="absolute top-0 z-10 w-full items-center bg-gradient-to-b via-gray-50 from-gray-50 dark:from-gray-800 dark:via-gray-800 px-2 pb-2 pt-2 rounded-t-lg">
        <h4
          className={`${
            isCompactView ? "text-sm" : "text-base"
          } text-center dark:text-gray-300 pb-2`}
        >
          <Twemoji emoji={"📂"} /> {attachmentPreviewText || "Files"}
        </h4>
      </div>
      {attachments?.length
        ? renderListOfAttachments()
        : renderEmptyState()}

      {renderViewAllAttachments()}
    </div>
  );
}
